/* eslint no-useless-escape:0 no-template-curly-in-string:0 */
module.exports = {
  path: 'pq',
  title: 'Product Quality',
  component: 'PropTypeSafety',
  tabs: [
    {
      title: 'Complaint',
      component: 'Tab',
      path: 'complaint',
      // Some reconciliation paths do not use the above `path` within their
      // actual `statePath` (or `$id`). This is a backwards-compatible property
      // used for TAB reconciliation notifications
      reconciliationPaths: [
        'subcases.productQuality.awareness_date',
        'subcases.productQuality.event_date',
        'subcases.productQuality.description',
        'subcases.productQuality.complaint.processing',
        'subcases.productQuality.complaint',
        'subcases.productQuality.event_date_unavailable_reason_comments',
        'subcases.productQuality.event_date_unavailable_reason',
        'subcases.productQuality.event_date_unavailable',
        'subcases.productQuality.affiliate_awareness_date'
      ],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'complaint' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          $ref: 'SharedCaseNotes'
        },
        {
          id: 'hubInfo',
          component: 'Section',
          statePath: 'subcases.productQuality.complaint.hub',
          title: 'Hub Info',
          elements: [
            {
              label: 'Registering Country',
              component: 'Select',
              statePath: 'registeringCountry',
              layout: {
                width: '50%'
              },
              helpText:
                'Complete to identify the registering country within the hub',
              referencedProperties: {
                options: {
                  schemaPath: './',
                  $get: 'data.document-data.regcountry-options'
                }
              }
            }
          ]
        },
        {
          $ref: 'DocumentUploader'
        },
        {
          $ref: 'PQSaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Product',
      component: 'Tab',
      path: 'product',
      reconciliationPaths: ['subcases.productQuality.pqproduct'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'product' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'Product',
          component: 'Section',
          statePath: 'subcases.productQuality.pqproduct',
          title: 'Products',
          elements: [
            {
              component: 'InputGroup',
              label: '',
              statePath: 'products',
              multiple: true,
              length: 1,
              addButtonLabel: 'Add another product',

              layout: {
                display: 'block'
              },

              referencedProperties: {
                label: {
                  statePath: './',
                  relative: true,
                  $or: [
                    {
                      $and: [
                        { $get: 'details[0].marketedName' },
                        {
                          $concat: [
                            '${order} - ',
                            { $get: 'details[0].marketedName' }
                          ]
                        }
                      ]
                    },
                    { $set: 'Product ${order}' }
                  ]
                },
                collapsed: {
                  schemaPath: './',
                  relative: true,
                  $if: [
                    { collapsed: { $eq: null } },
                    {
                      $var: ['productLength', { $get: '../length' }]
                    },
                    { order: { $lt: { $var: 'productLength' } } },
                    { $set: true }
                  ],
                  $else: {
                    $get: 'collapsed'
                  }
                }
              },
              elements: [
                {
                  component: 'SmartButton',
                  label: '',
                  triggers: {
                    onClick: 'onCopyProduct'
                  },
                  layout: {
                    width: '100%',
                    height: '1',
                    minHeight: 'unset',
                    position: 'relative',
                    padding: '0',
                    zIndex: '9'
                  },
                  styles: {
                    position: 'absolute',
                    right: '5px',
                    top: '0',
                    padding: '5px',
                    border: '0'
                  },
                  className: 'clone-product',
                  id: 'clone-product'
                },
                {
                  component: 'Section',
                  label: 'Description',
                  statePath: '',
                  layout: {
                    padding: '10px',
                    ' > span': {
                      fontSize: '18px',
                      fontWeight: 'bold',
                      width: '100%',
                      textAlign: 'left !important',
                      padding: '0 1px 10px 15px'
                    }
                  },
                  elements: [
                    {
                      component: 'TextArea',
                      label: 'PQ Description',
                      statePath: 'description',
                      layout: {
                        display: 'block',
                        width: '100%'
                      },
                      styles: {
                        height: '240px',
                        width: '100%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        }
                      }
                    },
                    {
                      label: 'Abbvie Awareness Date - PQ Complaint',
                      component: 'DateInput',
                      statePath: 'awareness_date',

                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        },
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      },
                      referencedProperties: {
                        'validations.required.constraint': {
                          $if: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        },
                        'layout.display': {
                          $if: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      label: 'Affiliate Awareness Date - PQ Complaint',
                      component: 'DateInput',
                      statePath: 'affiliate_awareness_date',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        },
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      },
                      referencedProperties: {
                        'validations.required.constraint': {
                          $if: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'PQ Complaint Event Date',
                      component: 'DateInput',
                      statePath: 'event_date',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      },
                      referencedProperties: {
                        'validations.required.constraint': {
                          statePath: 'event_date_unavailable',
                          relative: true,
                          $if: [
                            {
                              $eq: true
                            },
                            { $set: null }
                          ],
                          $else: { $set: true }
                        },
                        'layout.display': {
                          $if: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        disabled: {
                          statePath: 'event_date_unavailable',
                          relative: true,
                          $if: [
                            {
                              $eq: true
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      component: 'Checkbox',
                      label: 'Unavailable',
                      statePath: 'event_date_unavailable',
                      layout: {
                        paddingLeft: '15px',
                        paddingTop: '20px'
                      },
                      referencedProperties: {
                        'layout.display': {
                          $if: [
                            {
                              'summary.narrative.categories.product_quality': {
                                $eq: true
                              }
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        disabled: {
                          statePath: 'event_date',
                          relative: true,
                          $if: [{ $ne: null }, { $set: true }],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Why is the event date unavailable?',
                      component: 'Select',
                      statePath: 'event_date_unavailable_reason',
                      layout: {
                        width: '50%',
                        marginRight: '50%',
                        display: 'none'
                      },
                      options: [],
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.event_date_unavailable_reason'
                        },
                        'validations.required.constraint': {
                          statePath: 'event_date_unavailable',
                          relative: true,
                          $if: [{ $eq: true }, { $set: true }],
                          $else: { $set: false }
                        },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  '/summary.narrative.categories.product_quality': {
                                    $eq: true
                                  }
                                },
                                {
                                  event_date_unavailable: { $eq: true }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      // id: 'event_date_unavailable_reason_comments',
                      label: 'Additional Comments',
                      component: 'TextArea',
                      statePath: 'event_date_unavailable_reason_comments',
                      layout: {
                        width: '50%',
                        marginRight: '50%',
                        display: 'none'
                      },
                      styles: {
                        height: '176px',
                        width: '100%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  '/summary.narrative.categories.product_quality': {
                                    $eq: true
                                  }
                                },
                                {
                                  // need this in case Unavailable becomes deselected
                                  event_date_unavailable: {
                                    $eq: true
                                  }
                                },
                                {
                                  event_date_unavailable_reason: {
                                    $eq: 'other'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'High impact?',
                      name: 'highImpact',
                      component: 'RadioGroup',
                      statePath: 'complaint.processing.highImpact',
                      layout: {
                        display: 'inline-block',
                        width: '50%',
                        ' > div, > div > div': {
                          margin: 0
                        }
                      },
                      styles: {
                        flexDirection: 'row'
                      },
                      options: [
                        { label: 'No', value: 'no' },
                        { label: 'Yes', value: 'yes' }
                      ],
                      referencedProperties: {
                        'validations.required.constraint': {
                          relative: false,
                          statePath: 'contacts.contact',
                          $if: [
                            {
                              $count: {
                                $filter: {
                                  'pq.regulatory_agency_communication': {
                                    $eq: true
                                  }
                                }
                              }
                            },
                            { $set: true }
                          ],
                          $else: {
                            $set: false
                          }
                        }
                      }
                    },
                    {
                      component: 'DateInput',
                      statePath: 'awareness_date_follow_up',
                      label: 'PQ Complaint - Awareness Date Follow Up',
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Full date required'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      }
                    },
                    {
                      label: 'Country of Occurrence',
                      component: 'Select',
                      statePath: 'countryOfOccurance',
                      layout: {
                        width: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.country-options'
                        }
                      }
                    }
                  ]
                },
                {
                  component: 'Section',
                  statePath: 'tracking',
                  label: 'PQ Additional Comments',
                  layout: {
                    padding: '10px',
                    ' > span': {
                      fontSize: '18px',
                      fontWeight: 'bold',
                      width: '100%',
                      textAlign: 'left !important',
                      padding: '0 1px 10px 15px'
                    }
                  },
                  elements: [
                    {
                      id: 'additionalCommentItem',
                      component: 'InputGroup',
                      label: '',
                      addButtonLabel: 'Add a comment',
                      statePath: 'pqComments',
                      multiple: true,
                      collapsible: true,
                      length: 1,
                      layout: {
                        display: 'block',
                        ' > section > span ': {
                          display: 'none'
                        }
                      },
                      referencedProperties: {
                        label: {
                          relative: true,
                          $set: 'Comment ${order}'
                        },
                        'styles.display': {
                          schemaPath: './',
                          relative: true,
                          $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
                          $else: { $set: 'block' }
                        }
                      },
                      elements: [
                        {
                          component: 'TextArea',
                          label: 'Additional Comment',
                          statePath: 'pqComment',
                          layout: {
                            display: 'block',
                            marginRight: '25%',
                            width: '75%'
                          },
                          styles: {
                            height: '120px'
                          },
                          referencedProperties: {
                            'validations.required.constraint': {
                              relative: false,
                              statePath: 'complaint.processing.type',
                              $if: { $eq: 'follow_up' },
                              $else: { $set: false }
                            },
                            disabled: {
                              statePath: 'createdDate',
                              relative: true,
                              $if: [{ $ne: null }, { $set: true }],
                              $else: { $set: false }
                            }
                          }
                        },
                        {
                          component: 'LabelValue',
                          label: 'Created on',
                          statePath: 'createdDate',
                          layout: {
                            width: 'initial',
                            paddingRight: '4px',
                            ' > span > span ': {
                              fontWeight: '300'
                            }
                          },
                          valueTemplate:
                            '<%= triggers.getAbbvieDateIfValidFullDate(value) %>',
                          referencedProperties: {
                            'layout.display': {
                              statePath: 'createdDate',
                              relative: true,
                              $if: [{ $ne: null }, { $set: 'block' }],
                              $else: { $set: 'none' }
                            }
                          }
                        },
                        {
                          component: 'LabelValue',
                          label: 'by',
                          statePath: 'userId',
                          layout: {
                            width: 'initial',
                            paddingLeft: '4px',
                            ' > span > span ': {
                              fontWeight: '300'
                            }
                          },
                          valueTemplate: '<%= getUserName(users, value) %>',
                          referencedProperties: {
                            'layout.display': {
                              statePath: 'userId',
                              relative: true,
                              $if: [{ $ne: null }, { $set: 'block' }],
                              $else: { $set: 'none' }
                            }
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  component: 'InputGroup',
                  label: 'Manufacturing Details',
                  statePath: 'details',
                  multiple: false,
                  length: 1,
                  collapsible: false,
                  layout: {
                    paddingLeft: '10px'
                  },
                  // TODO Figure out issue of prop inheritence
                  referencedProperties: {
                    label: {
                      $set: 'Manufacturing Details'
                    }
                  },
                  elements: [
                    {
                      component: 'TextInput',
                      label: 'Batch/ Lot Number',
                      statePath: 'lotNumber',
                      disabled: true,
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        },
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'validations.required.constraint': {
                          statePath: 'availability',
                          relative: true,
                          $if: [{ $ne: true }, { $set: true }],
                          $else: { $set: false }
                        },
                        // disabled: {
                        //   statePath: 'availability',
                        //   relative: true,
                        //   $if: [{ $eq: true }, { $set: true }],
                        //   $else: { $set: false }
                        // }
                      }
                    },
                    {
                      component: 'Checkbox',
                      statePath: 'availability',
                      label: 'Unavailable',
                      layout: {
                        padding: '20px',
                        width: '20%'
                      },
                      referencedProperties: {
                        // 'layout.marginRight': {
                        //   relative: true,
                        //   $if: [
                        //     { availability: { $eq: true } },
                        //     { reason: { $eq: 'Other' } },
                        //     { $set: '0' }
                        //   ],
                        //   $else: { $set: '50%' }
                        // },
                        // disabled: {
                        //   relative: true,
                        //   $if: {
                        //     $get: 'lotNumber'
                        //   },
                        //   $else: {
                        //     $set: false
                        //   }
                        // }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'serialNumber',
                      label: 'Serial Number',
                      layout: {
                        width: '30%'
                      }
                    },
                    {
                      label: 'LOOK UP BATCH/ LOT NUMBER',
                      component: 'SmartButton',
                      id: 'pq-batchlot-lookup-product-btn',
                      triggers: {
                        onClick: 'onPQBatchLotLookup'
                      },
                      layout: {
                        width: '25%'
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#071D49',
                        border: 'none',
                        color: '#FFFFFF',
                        marginLeft: 'auto',
                        width: '100%',
                        height: '30px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '13px',
                        float: 'right'
                      }
                    },
                    {
                      label: 'REMOVE PRODUCT',
                      id: 'pq-lookup-batch-clear-btn',
                      component: 'SmartButton',
                      triggers: {
                        onClick: 'onPQBatchLookUpClear'
                      },
                      layout: {
                        width: '25%',
                       
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #0082BA',
                        color: '#0082BA',
                        marginLeft: 'auto',
                        width: '100%',
                        height: '30px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      }
                    },
                                        {
                      label: 'Why is the batch/lot number unavailable?',
                      component: 'Select',
                      statePath: 'reason',
                      options: [],
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: false,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        'layout.visibility': {
                          statePath: 'availability',
                          relative: true,
                          $if: [{ $eq: true }, { $set: 'visible' }],
                          $else: { $set: 'hidden' }
                        },
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.lot_number_unavailable_reason'
                        },
                        value: {
                          relative: true,
                          schemaPath: './',
                          $if: [
                            {
                              'layout.visibility': {
                                $eq: 'hidden'
                              }
                            },
                            {
                              $set: null
                            }
                          ],
                          $else: {
                            $get: 'value'
                          }
                        },
                        'validations.required.constraint': {
                          relative: true,
                          schemaPath: './',
                          $if: [
                            {
                              'layout.visibility': {
                                $eq: 'visible'
                              }
                            },
                            {
                              $set: true
                            }
                          ],
                          $else: {
                            $set: false
                          }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      statePath: 'additional_comments',
                      label: 'Batch Number Additional Comments',
                      layout: {
                        width: '50%',
                        marginLeft: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true
                        },
                        maxLength: {
                          constraint: 100000,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'reason',
                          relative: true,
                          $if: [{ $eq: 'Other' }, { $set: 'block' }],
                          $else: {
                            $set: 'none'
                          }
                        },
                        value: {
                          relative: true,
                          $if: [
                            { availability: { $eq: true } },
                            { reason: { $eq: 'Other' } },
                            { $this: { $get: 'value' } }
                          ],
                          $else: { $set: null }
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      label: 'List Number',
                      statePath: 'listNumber',
                      // helpText: 'Add List Number using the product look up',
                      disabled: true,
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        },
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      label: 'Marketed Name',
                      statePath: 'marketedName',
                      // helpText: 'Add Marketed Name using the product look up',
                      disabled: true,
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        },
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      label: 'Product Family',
                      statePath: 'productFamily',
                      // helpText: 'Add Product Family Name using the product look up',
                      disabled: true,
                      layout: {
                        display: 'none'
                      }
                    },
                    {
                      label: 'LOOK UP PRODUCT',
                      component: 'SmartButton',
                      id: 'pq-lookup-product-btn',
                      triggers: {
                        onClick: 'onPQProductLookup'
                      },
                      layout: {
                        width: '25%'
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#071D49',
                        border: 'none',
                        color: '#FFFFFF',
                        marginLeft: 'auto',
                        width: '100%',
                        height: '30px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      }
                    },
                    {
                      label: 'REMOVE PRODUCT',
                      id: 'pq-lookup-product-clear-btn',
                      component: 'SmartButton',
                      triggers: {
                        onClick: 'onPQProductLookUpClear'
                      },
                      layout: {
                        width: '25%',
                        
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #0082BA',
                        color: '#0082BA',
                        marginLeft: 'auto',
                        width: '100%',
                        height: '30px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'unitsImpacted',
                      label: 'Quantity Affected',
                      options: [],
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.unitsImpacted'
                        }
                      }
                    },
                    // Moved this Field from here to Reported Category Input Group
                    // {
                    //   $ref: 'DisplayValueToggle',
                    //   component: 'Select',
                    //   statePath: 'complaintProductType',
                    //   label: 'Complaint Product Type',
                    //   placeholder: 'Select...',

                    //   layout: {
                    //     width: '50%'
                    //   },

                    //   referencedProperties: {
                    //     options: {
                    //       schemaPath: './',
                    //       $get:
                    //         'data.document-data.pq-options.complaint-product-type'
                    //     }
                    //   }
                    // },
                    {
                      component: 'DateInput',
                      label: 'Expiration Date',
                      statePath: 'expirationDate',
                      layout: {
                        width: '50%'
                      },
                      referencedProperties: {
                        disabled: {
                          relative: true,
                          statePath: 'availability',
                          $if: [
                            {
                              $eq: true
                            },
                            {
                              $set: true
                            }
                          ],
                          $else: { $set: false }
                        },
                        value: {
                          relative: true,
                          $if: [
                            { availability: { $eq: true } },
                            { $set: null }
                          ],
                          $else: {
                            $set: { $get: 'expirationDate' }
                          }
                        }
                      }
                    },
                    {
                      component: 'Select',
                      label: 'QA Responsible Site',
                      statePath: 'qaSite',
                      placeholder: 'Select site',
                      options: [],
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.qa_site'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      label: 'Indication',
                      statePath: 'indication',
                      placeholder: 'Select an Indication',
                      options: [],
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.indication'
                        }
                      }
                    },
                    {
                      component: 'TextInput',
                      label: 'General Indication',
                      statePath: 'generalIndication',
                      layout: {
                        width: '50%'
                      }
                    },
                    {
                      component: 'DateInput',
                      label: 'Date PEG Placement',
                      statePath: 'pegPlacement',
                      helpText: 'Date of original or recent PEG placement',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      }
                    },
                    {
                      component: 'DateInput',
                      label: 'Date Intestinal Tube Placement',
                      statePath: 'intestinalTubePlacement',
                      helpText:
                        'Date of original or recent intestinal tube placement',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      }
                    },
                    {
                      component: 'DateInput',
                      label: 'Explant Date',
                      statePath: 'explantDate',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      statePath: 'numberOfReplacement',
                      label: 'Number of Replacement',
                      options: [],
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.numberOfReplacement'
                        }
                      }
                    },
                    {
                      component: 'DateInput',
                      statePath: 'implantDate',
                      label: 'Implant Date',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      }
                    }
                  ]
                },
                {
                  component: 'InputGroup',
                  label: 'Product Use',
                  statePath: 'usage',
                  multiple: false,
                  length: 1,
                  collapsible: true,
                  layout: {
                    paddingLeft: '10px'
                  },
                  elements: [
                    {
                      component: 'Checkbox',
                      statePath: 'useError',
                      label: 'Complaint related to use error?',
                      layout: {
                        padding: 15
                      }
                    },
                    {
                      component: 'Select',
                      label: 'Length of Product Use',
                      statePath: 'usageDuration',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      options: [],
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.usageDuration'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      label: 'Who trained the patient in product usage?',
                      statePath: 'trainer',
                      placeholder: 'Select trainer',
                      options: [],
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.trainer'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      label: 'What kind of training did the patient receive?',
                      statePath: 'trainingKind',
                      placeholder: 'Select training',
                      options: [],
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.trainingKind'
                        }
                      }
                    },
                    {
                      component: 'Select',
                      label: 'Treatment Administration Usage',
                      statePath: 'treatmentAdministrationUsage',
                      placeholder: 'Select Administartion Usage',
                      options: [],
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.treatmentAdministrationUsage'
                        }
                      }
                    }
                  ]
                },
                {
                  id: 'complaintType',
                  component: 'Section',
                  statePath: 'complaint',
                  label: 'Complaint Type',
                  layout: {
                    padding: '10px',
                    ' > span': {
                      fontSize: '18px',
                      fontWeight: 'bold',
                      width: '100%',
                      textAlign: 'left !important',
                      padding: '0 1px 10px 15px'
                    }
                  },
                  elements: [
                    {
                      component: 'Select',
                      statePath: 'type',
                      label: 'Complaint Classification',
                      placeholder: 'Select complaint',
                      options: [],
                      layout: {
                        marginRight: '50%',
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.complaint_classification'
                        }
                      }
                    },
                    {
                      component: 'InputGroup',
                      id: 'ReportedCategory',
                      statePath: 'reportedCategories',
                      addButtonLabel: 'Add another As Reported Code',
                      label: '',
                      collapsible: false,
                      multiple: true,
                      length: 1,
                      layout: {
                        position: 'relative'
                      },
                      referencedProperties: {
                        label: {
                          relative: true,
                          $set: 'As Reported Code ${order}'
                        }
                      },
                      elements: [
                        {
                          // $ref: 'DisplayValueToggle',
                          component: 'Select',
                          statePath: 'ProductType',
                          label: 'Product Type',
                          placeholder: 'Select...',
                          triggers: {
                            onChange: 'onPQCategorySelectChange'
                          },
                          layout: {
                            width: '50%',
                            marginRight: '50%'
                          },
                          
                          referencedProperties: {
                            options: {
                              schemaPath: './',
                              $get:
                                'data.document-data.reported-options.product_type'
                            },
                            value: { $this: { $get: 'value' } }
                          }
                        },
                        {
                          $ref: 'ProductType',
                          component: 'Select',
                          statePath: 'reportedCategory',
                          label: 'As Reported Category',
                          placeholder: 'Select Category',
                          triggers: {
                            onChange: 'onPQCategorySelectChange'
                          },
                          layout: {
                            width: '50%'
                          },
                          stateParentPath: 'ProductType',
                          parentDataPath:
                            'data.document-data.reported-options.product_type',
                          referencedProperties: {
                            options: {
                              schemaPath: './',
                              $get:
                                'data.document-data.reported-options.reported_category'
                            },
                            // value: { $this: { $get: 'value' } }
                            value: {
                              relative: true,
                              statePath: 'ProductType',
                              $if: [
                                { $ne: null },
                                // { $this: { $get: 'value' } }

                                { $this: { $get: 'value' } }
                              ],
                              $else: { $set: null }
                            }
                          }
                        },
                        {
                          $ref: 'reportedCategory',
                          component: 'Select',
                          statePath: 'reportedSubcategory',
                          label: 'As Reported Sub-Category',
                          placeholder: 'Select Sub-Category',
                          triggers: {
                            onChange: 'onPQCategorySelectChange'
                          },
                          layout: {
                            width: '50%'
                          },
                          stateParentPath: 'reportedCategory',
                          parentDataPath:
                            'data.document-data.reported-options.reported_category',
                          referencedProperties: {
                            options: {
                              schemaPath: './',
                              $get:
                                'data.document-data.reported-options.reported_sub_category'
                            },
                            value: {
                              relative: true,
                              statePath: 'reportedCategory',
                              $if: [
                                { $ne: null },
                                { $this: { $get: 'value' } }
                              ],
                              $else: { $set: null }
                            }
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  component: 'Section',
                  statePath: 'complaint.processing',
                  label: 'Processing Info',
                  layout: {
                    padding: '10px',
                    ' > span': {
                      fontSize: '18px',
                      fontWeight: 'bold',
                      width: '100%',
                      textAlign: 'left !important',
                      padding: '0 1px 10px 15px'
                    }
                  },
                  elements: [
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'Select',
                      statePath: 'replacement',
                      label: 'Replacement required?',
                      placeholder: 'Select option',
                      options: [],
                      layout: {
                        marginRight: '50%',
                        width: '50%'
                      },
                      referencedProperties: {
                        'layout.marginRight': {
                          statePath: './',
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { $eq: 'creditOnly' },
                                { $eq: 'yes' },
                                { $eq: 'USCreditOnly' }
                              ]
                            },
                            { $set: '0' }
                          ],
                          $else: { $set: '50%' }
                        },
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.replacement'
                        }
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Reimbursement Number',
                      component: 'TextInput',
                      statePath: 'reimbursementNumber',

                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [{ $eq: 'USCreditOnly' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'Select',
                      statePath: 'forwardTo',
                      label: 'Forward to:',
                      placeholder: 'Select recipient',
                      options: [],
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        },
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.forwardTo'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'Select',
                      statePath: 'reasonForReplacement',
                      label: 'Reason for Replacement',
                      placeholder: 'Select option',
                      options: [],
                      layout: {
                        marginRight: '0',
                        width: '50%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $ne: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.reasonForReplacement'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      label: 'Reason for Replacement',
                      statePath: 'replacementReason',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $eq: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Replacement Reference Number',
                      component: 'TextInput',
                      statePath: 'replacementReferenceNumber',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextArea',
                      label: 'Additional Credit Information',
                      statePath: 'additionalCreditInformation',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      styles: {
                        minHeight: '150px'
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [{ $eq: 'USCreditOnly' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Number of Units to Send',
                      component: 'TextInput',
                      statePath: 'numberOfUnits',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [
                            { $or: [{ $eq: 'yes' }, { $eq: 'USCreditOnly' }] },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        'validations.required.constraint': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                {
                                  replacement: {
                                    $eq: 'USCreditOnly'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $eq: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        },
                        'layout.marginRight': {
                          statePath: 'forwardTo',
                          relative: true,
                          $if: [
                            {
                              $or: [
                                { $eq: 'US Customer Service' },
                                { $eq: 'US Pharmacy Solutions' }
                              ]
                            },
                            { $set: '50%' }
                          ],
                          $else: { $set: '0' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      label: 'Replacement List Number',
                      statePath: 'listNumber',
                      disabled: true,
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $eq: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      label: 'Replacement Marketed Name',
                      statePath: 'marketedName',
                      disabled: true,
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $eq: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      label: 'LOOK UP PRODUCT',
                      component: 'SmartButton',
                      triggers: {
                        onClick: 'onPQProductLookup'
                      },
                      layout: {
                        width: '25%',
                        display: 'none'
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#071D49',
                        border: 'none',
                        color: '#FFFFFF',
                        marginLeft: 'auto',
                        width: '100%',
                        height: '40px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $eq: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      label: 'COPY PRODUCT FROM ABOVE',
                      component: 'SmartButton',
                      triggers: {
                        onClick: 'replaceOriginalProduct'
                      },
                      layout: {
                        width: '25%',
                        display: 'none',
                        marginRight: '50%'
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#071D49',
                        border: 'none',
                        color: '#FFFFFF',
                        marginLeft: 'auto',
                        width: '100%',
                        height: '40px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $eq: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'Checkbox',
                      label: 'Urgent',
                      statePath: 'urgentReplacement',
                      layout: {
                        padding: '20px',
                        width: '50%'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  $or: [
                                    {
                                      forwardTo: {
                                        $eq: 'US Pharmacy Solutions'
                                      }
                                    },
                                    {
                                      forwardTo: {
                                        $eq: 'US Customer Service'
                                      }
                                    }
                                  ]
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'LabelValue',
                      label: 'Last Sent',
                      statePath: 'lastCustomerServiceEmailSentDate',
                      layout: {
                        width: '25%',
                        ' > span > [role="button"] ': {
                          display: 'block'
                        },
                        padding: '15px 0px 0px 15px'
                      },
                      valueTemplate:
                        '<%= triggers.getAbbvieDateAndAtTime(value) %>',
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                {
                                  replacement: {
                                    $eq: 'USCreditOnly'
                                  }
                                },
                                {
                                  $and: [
                                    {
                                      replacement: {
                                        $eq: 'yes'
                                      }
                                    },
                                    {
                                      $or: [
                                        {
                                          forwardTo: {
                                            $eq: 'US Pharmacy Solutions'
                                          }
                                        },
                                        {
                                          forwardTo: {
                                            $eq: 'US Customer Service'
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        'layout.marginLeft': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [{ $eq: 'USCreditOnly' }, { $set: '50%' }],
                          $else: { $set: '0' }
                        }
                      }
                    },
                    {
                      component: 'SmartButton',
                      triggers: {
                        onClick: 'onSend'
                      },
                      layout: {
                        width: '25%',
                        padding: '20px 10px'
                      },
                      styles: {
                        appearance: 'none',
                        backgroundColor: '#071D49',
                        border: 'none',
                        color: '#FFFFFF',
                        marginLeft: 'auto',
                        width: '100%',
                        height: '30px',
                        fontFamily: 'Calibri, "Microsoft Sans Serif"',
                        fontSize: '14px',
                        float: 'right'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                {
                                  replacement: {
                                    $eq: 'USCreditOnly'
                                  }
                                },
                                {
                                  $and: [
                                    {
                                      replacement: {
                                        $eq: 'yes'
                                      }
                                    },
                                    {
                                      $or: [
                                        {
                                          forwardTo: {
                                            $eq: 'US Pharmacy Solutions'
                                          }
                                        },
                                        {
                                          forwardTo: {
                                            $eq: 'US Customer Service'
                                          }
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        label: {
                          statePath: 'forwardTo',
                          relative: true,
                          $if: [
                            { $eq: 'US Pharmacy Solutions' },
                            { $set: 'Send to PS' }
                          ],
                          $else: { $set: 'Send to CS' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      id: 'patientInfo',
                      component: 'InputGroup',
                      label: 'Patient Info',
                      statePath: 'patientInfo',
                      multiple: false,
                      collapsible: true,
                      length: 1,
                      layout: {
                        display: 'block',
                        ' > section > span ': {
                          display: 'none'
                        }
                      },
                      referencedProperties: {
                        'styles.display': {
                          schemaPath: './',
                          relative: true,
                          $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
                          $else: { $set: 'block' }
                        },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  $or: [
                                    {
                                      forwardTo: {
                                        $eq: 'US Pharmacy Solutions'
                                      }
                                    },
                                    {
                                      forwardTo: {
                                        $eq: 'US Customer Service'
                                      }
                                    }
                                  ]
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      },
                      elements: [
                        {
                          label: 'First Name',
                          component: 'TextInput',
                          statePath: 'firstName',
                          disabled: true,
                          layout: {
                            width: '50%',
                            marginTop: '10px'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get: '/model.patient.patient[0].firstName'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'Last Name',
                          component: 'TextInput',
                          statePath: 'lastName',
                          disabled: true,
                          layout: {
                            width: '50%',
                            marginTop: '10px'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get: '/model.patient.patient[0].lastName'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                {
                                  $or: [
                                    {
                                      $eq: 'US Pharmacy Solutions'
                                    },
                                    {
                                      $eq: 'US Customer Service'
                                    }
                                  ]
                                },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'Date of Birth',
                          component: 'DateInput',
                          statePath: 'dob',
                          disabled: true,
                          layout: {
                            display: 'inline-block',
                            paddingBottom: '20px'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get: '/model.patient.patient[0].dob'
                                }
                              }
                            },
                            'layout.marginRight': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Customer Service' },
                                { $set: '0' }
                              ],
                              $else: { $set: '50%' }
                            },
                            'layout.marginTop': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Customer Service' },
                                { $set: '10px' }
                              ],
                              $else: { $set: '0' }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                {
                                  $or: [
                                    {
                                      $eq: 'US Pharmacy Solutions'
                                    },
                                    {
                                      $eq: 'US Customer Service'
                                    }
                                  ]
                                },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'Address (Line 1)',
                          component: 'TextInput',
                          statePath: 'line1',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].line1'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'Address (Line 2)',
                          component: 'TextInput',
                          statePath: 'line2',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].line2'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'City',
                          component: 'TextInput',
                          statePath: 'city',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].city'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'State/Province/Region',
                          component: 'TextInput',
                          statePath: 'state',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].state'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'Postal/Zip Code',
                          component: 'TextInput',
                          statePath: 'postal_code',
                          disabled: true,
                          layout: {
                            width: '50%',
                            marginBottom: '20px'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].postal_code'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          label: 'Country',
                          component: 'Select',
                          statePath: 'country',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            options: {
                              schemaPath: './',
                              $get: 'data.document-data.country-options'
                            },
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].country'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          component: 'TextInput',
                          statePath: 'address',
                          label: 'Email Address',
                          disabled: true,
                          layout: {
                            width: '50%',
                            marginBottom: '20px'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].email[0].address'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          component: 'TextInput',
                          statePath: 'number',
                          label: 'Phone Number',
                          disabled: true,
                          layout: {
                            flex: '0 1 auto',
                            width: '33%',
                            marginRight: '1px'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].phone[0].number'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        },
                        {
                          component: 'TextInput',
                          statePath: 'extension',
                          label: 'Extension',
                          disabled: true,
                          layout: {
                            flex: '0 1 auto',
                            padding: 0,
                            width: '15%'
                          },
                          referencedProperties: {
                            value: {
                              relative: true,
                              schemaPath: './',
                              $if: [
                                {
                                  'layout.display': {
                                    $eq: 'none'
                                  }
                                },
                                {
                                  $set: null
                                }
                              ],
                              $else: {
                                $set: {
                                  $get:
                                    '/model.patient.patient[0].contact_info[0].phone[0].extension'
                                }
                              }
                            },
                            'layout.display': {
                              statePath: '../forwardTo',
                              relative: true,
                              $if: [
                                { $eq: 'US Pharmacy Solutions' },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            },
                            _checkReplacement: {
                              statePath: '../replacement',
                              relative: true
                            }
                          }
                        }
                      ]
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      id: 'prescriberInfo',
                      component: 'InputGroup',
                      label: 'Prescriber Info',
                      statePath: 'prescriberInfo',
                      multiple: false,
                      collapsible: true,
                      length: 1,
                      layout: {
                        display: 'block',
                        ' > section > span ': {
                          display: 'none'
                        }
                      },
                      referencedProperties: {
                        'styles.display': {
                          schemaPath: './',
                          relative: true,
                          $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
                          $else: { $set: 'block' }
                        },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                },
                                {
                                  forwardTo: {
                                    $eq: 'US Pharmacy Solutions'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      },
                      elements: [
                        {
                          label: 'First Name',
                          component: 'TextInput',
                          statePath: 'firstName',
                          disabled: true,
                          layout: {
                            width: '50%',
                            marginTop: '10px'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'name.first' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Last Name',
                          component: 'TextInput',
                          statePath: 'lastName',
                          disabled: true,
                          layout: {
                            width: '50%',
                            marginTop: '10px'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'name.last' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Address (Line 1)',
                          component: 'TextInput',
                          statePath: 'line1',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'address[0].line1' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Address (Line 2)',
                          component: 'TextInput',
                          statePath: 'line2',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'address[0].line2' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'City',
                          component: 'TextInput',
                          statePath: 'city',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'address[0].city' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'State/Province/Region',
                          component: 'TextInput',
                          statePath: 'state',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'address[0].state' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Postal/Zip Code',
                          component: 'TextInput',
                          statePath: 'postal_code',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'address[0].postal_code' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Country',
                          component: 'TextInput',
                          statePath: 'country',
                          disabled: true,
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'country' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Email',
                          component: 'TextInput',
                          statePath: 'email',
                          disabled: true,
                          layout: {
                            width: '50%',
                            marginBottom: '20px'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'email[0].address' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Phone Number',
                          component: 'TextInput',
                          statePath: 'phoneNumber',
                          disabled: true,
                          layout: {
                            width: '25%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'phone[0].number' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Extension',
                          component: 'TextInput',
                          statePath: 'extension',
                          disabled: true,
                          layout: {
                            width: '15%'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'phone[0].extension' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        },
                        {
                          label: 'Fax',
                          component: 'Checkbox',
                          statePath: 'isFax',
                          disabled: true,
                          layout: {
                            width: '10%',
                            marginTop: '25px'
                          },
                          referencedProperties: {
                            value: {
                              $if: {
                                $compose: [
                                  {
                                    'contacts.contact': {
                                      $last: {
                                        $filter: {
                                          pq: {
                                            prescriber: { $eq: true }
                                          }
                                        }
                                      }
                                    }
                                  },
                                  { $get: 'phone[0].isFax' }
                                ]
                              },
                              $else: { $set: null }
                            },
                            _updatePrescriberInfo: {
                              schemaPath: 'data'
                            }
                          }
                        }
                      ]
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      id: 'shippingInfo',
                      component: 'InputGroup',
                      label: 'Shipping Info',
                      statePath: 'shippingInfo',
                      multiple: false,
                      collapsible: true,
                      length: 1,
                      layout: {
                        display: 'block',
                        ' > section > span ': {
                          display: 'none'
                        }
                      },
                      referencedProperties: {
                        'styles.display': {
                          schemaPath: './',
                          relative: true,
                          $if: [{ collapsed: { $eq: true } }, { $set: 'none' }],
                          $else: { $set: 'block' }
                        },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $or: [
                                {
                                  $and: [
                                    { shipTo: { $eq: null } },
                                    { replacement: { $eq: 'yes' } },
                                    {
                                      forwardTo: {
                                        $eq: 'US Customer Service'
                                      }
                                    }
                                  ]
                                },
                                { replacement: { $eq: 'USCreditOnly' } }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      },
                      elements: [
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'attention',
                          label: 'ATTN:',
                          layout: {
                            width: '50%',
                            marginRight: '50%'
                          },
                          referencedProperties: {
                            'layout.display': {
                              relative: true,
                              statePath: '../',
                              $if: [
                                {
                                  $and: [
                                    {
                                      replacement: {
                                        $eq: 'yes'
                                      }
                                    },
                                    {
                                      forwardTo: {
                                        $eq: 'US Customer Service'
                                      }
                                    }
                                  ]
                                },
                                { $set: 'block' }
                              ],
                              $else: { $set: 'none' }
                            }
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'type',
                          label: 'Type of Contact',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'accountNumber',
                          label: 'Account Number',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'firstName',
                          label: 'First Name',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'lastName',
                          label: 'Last Name',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'organization',
                          label: 'Organization/Company',
                          disabled: true,
                          layout: {
                            width: '50%',
                            paddingBottom: '50px'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'deaNumber',
                          label: 'DEA Number',
                          disabled: true,
                          helpText:
                            'DEA Number is required for replacement of controlled substances',
                          layout: {
                            width: '50%',
                            paddingBottom: '50px'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'line1',
                          label: 'Address (Line 1)',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'line2',
                          label: 'Address (Line 2)',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'city',
                          label: 'City',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'state',
                          label: 'State/Province/Region',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'postal_code',
                          label: 'Postal/Zip Code',
                          disabled: true,
                          layout: {
                            width: '50%'
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'Select',
                          statePath: 'country',
                          label: 'Country',
                          disabled: true,
                          options: [],
                          layout: {
                            width: '50%'
                          },
                          referencedProperties: {
                            options: {
                              schemaPath: './',
                              $get: 'data.document-data.country-options'
                            }
                          }
                        },
                        {
                          $ref: 'DisplayValueToggle',
                          component: 'TextInput',
                          statePath: 'phoneNumber',
                          label: 'Phone Number',
                          disabled: true,
                          layout: {
                            width: '50%',
                            paddingBottom: '50px'
                          }
                        }
                      ]
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextArea',
                      label: 'Additional Replacement Information',
                      statePath: 'additionalReplacementInformation',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      styles: {
                        minHeight: '150px'
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextArea',
                      label: 'Ship To',
                      statePath: 'shipTo',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      styles: {
                        minHeight: '150px'
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  $or: [
                                    {
                                      forwardTo: {
                                        $eq: null
                                      }
                                    },
                                    {
                                      forwardTo: {
                                        $eq: 'Customer Service'
                                      }
                                    },
                                    {
                                      forwardTo: {
                                        $eq: 'Non-US'
                                      }
                                    },
                                    {
                                      forwardTo: {
                                        $eq: 'Patient Assistance Program (PAP)'
                                      }
                                    }
                                  ]
                                },
                                {
                                  replacement: {
                                    $eq: 'yes'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        },
                        'validations.required.constraint': {
                          statePath: 'forwardTo',
                          relative: true,
                          $if: [
                            { $eq: 'US Pharmacy Solutions' },
                            { $set: true }
                          ],
                          $else: { $set: false }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Sample available for return?',
                      component: 'Select',
                      statePath: 'available',
                      layout: {
                        width: '50%'
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.sample_availability'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Why is the sample unavailable?',
                      component: 'Select',
                      statePath: 'availableReason',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorText: ''
                        }
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.sample_unavailable_reason'
                        },
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              available: {
                                $eq: 'no'
                              }
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextInput',
                      label: 'Sample Unavailable Reason',
                      statePath: 'sampleUnavailableReason',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  availableReason: {
                                    $eq: 'other'
                                  }
                                },
                                {
                                  available: {
                                    $eq: 'no'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },

                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Affiliate Sample Ship Date',
                      component: 'DateInput',
                      statePath: 'affiliate_sample_ship_date',
                      helpText:
                        'Date the affiliate ships the sample to the investigation site',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      },

                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  '/summary.narrative.categories.product_quality': {
                                    $eq: true
                                  }
                                },
                                {
                                  available: {
                                    $eq: 'yes'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label:
                        'This product is being provided free of charge by AbbVie as a replacement product. Seeking reimbursement from any third party payer, including Medicare and Medicaid is strictly prohibited.',
                      component: 'Checkbox',
                      statePath: 'isProductReplacementWithFreeOfCharge',
                      layout: {
                        display: 'flex',
                        width: '100%',
                        margin: '10px 0 25px 15px',
                        '>span': {
                          whiteSpace: 'normal'
                        },
                        '>input': {
                          marginTop: '5px'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'replacement',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'flex' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Sample Required for Investigation?',
                      component: 'Select',
                      statePath: 'sampleRequiredForInvestigation',
                      layout: {
                        width: '50%'
                      },
                      options: [],
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.sample_required'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Affiliate Ret Sample Rec Date',
                      component: 'DateInput',
                      statePath: 'affiliate_ret_sample_rec_date',
                      helpText:
                        'Date the affiliate receives the return sample.',
                      layout: {
                        width: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        },
                        dateRestriction: {
                          constraint: 'future',
                          errorMessage: 'Date must not occur in the future'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          relative: true,
                          $if: [
                            {
                              $and: [
                                {
                                  '/summary.narrative.categories.product_quality': {
                                    $eq: true
                                  }
                                },
                                {
                                  available: {
                                    $eq: 'yes'
                                  }
                                }
                              ]
                            },
                            { $set: 'block' }
                          ],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      component: 'Section',
                      layout: {
                        margin: 0
                      },
                      elements: [
                        {
                          component: 'Section',
                          layout: {
                            width: '50%',
                            padding: 0,
                            '> div:nth-child(1)': {
                              padding: '0 15px 10px 0'
                            }
                          },
                          elements: [
                            {
                              $ref: 'DisplayValueToggle',
                              component: 'TextArea',
                              label: 'Sample Return Info/Description',
                              statePath: 'sample_return_info_description',
                              layout: {
                                width: '1000%'
                              },
                              styles: {
                                minHeight: '150px'
                              },
                              validations: {
                                required: {
                                  constraint: false,
                                  errorMessage: ''
                                },
                                maxLength: {
                                  constraint: 20000,
                                  errorMessage:
                                    'The maximum number of characters has been reached'
                                }
                              },
                              referencedProperties: {
                                'layout.display': {
                                  relative: true,
                                  $if: [
                                    {
                                      $and: [
                                        {
                                          '/summary.narrative.categories.product_quality': {
                                            $eq: true
                                          }
                                        },
                                        {
                                          
                                          $or: [
                                            {
                                              available: {
                                                $eq: 'yes'
                                              }
                                            },
                                            {
                                              available: {
                                                $eq: 'no'
                                              }
                                            },
                                            {
                                              available: {
                                                $eq: 'Photo available'
                                              }
                                            }
                                          ]
                                        }
                                      ]
                                    },
                                    { $set: 'block' }
                                  ],
                                  $else: { $set: 'none' }
                                }
                              }
                            }
                          ]
                        },
                        {
                          component: 'Section',
                          layout: {
                            width: '50%',
                            padding: 0
                          },
                          elements: [
                            {
                              $ref: 'DisplayValueToggle',
                              label: 'Sample Tracking to Customer',
                              component: 'TextInput',
                              statePath: 'sampleTrackingToCustomer',
                              layout: {
                                width: '100%',
                                padding: 3
                              },
                              validations: {
                                maxLength: {
                                  constraint: 254,
                                  errorMessage:
                                    'The maximum number of characters has been reached'
                                }
                              },
                              referencedProperties: {
                                'layout.display': {
                                  relative: true,
                                  $if: [
                                    {
                                      $and: [
                                        {
                                          '/summary.narrative.categories.product_quality': {
                                            $eq: true
                                          }
                                        },
                                        {
                                          available: {
                                            $eq: 'yes'
                                          }
                                        }
                                      ]
                                    },
                                    { $set: 'block' }
                                  ],
                                  $else: { $set: 'none' }
                                }
                              }
                            },
                            {
                              $ref: 'DisplayValueToggle',
                              label: 'Sample Tracking from Customer',
                              component: 'TextInput',
                              statePath: 'sampleTrackingFromCustomer',
                              layout: {
                                width: '100%',
                                padding: 3
                              },
                              validations: {
                                maxLength: {
                                  constraint: 254,
                                  errorMessage:
                                    'The maximum number of characters has been reached'
                                }
                              },
                              referencedProperties: {
                                'layout.display': {
                                  relative: true,
                                  $if: [
                                    {
                                      $and: [
                                        {
                                          '/summary.narrative.categories.product_quality': {
                                            $eq: true
                                          }
                                        },
                                        {
                                          available: {
                                            $eq: 'yes'
                                          }
                                        }
                                      ]
                                    },
                                    { $set: 'block' }
                                  ],
                                  $else: { $set: 'none' }
                                }
                              }
                            },
                            {
                              $ref: 'DisplayValueToggle',
                              label: 'Sample Tracking to Lab',
                              component: 'TextInput',
                              statePath: 'sampleTrackingToLab',
                              layout: {
                                width: '100%',
                                padding: 3
                              },
                              validations: {
                                maxLength: {
                                  constraint: 254,
                                  errorMessage:
                                    'The maximum number of characters has been reached'
                                }
                              },
                              referencedProperties: {
                                'layout.display': {
                                  relative: true,
                                  $if: [
                                    {
                                      $and: [
                                        {
                                          '/summary.narrative.categories.product_quality': {
                                            $eq: true
                                          }
                                        },
                                        {
                                          available: {
                                            $eq: 'yes'
                                          }
                                        }
                                      ]
                                    },
                                    { $set: 'block' }
                                  ],
                                  $else: { $set: 'none' }
                                }
                              }
                            }
                          ]
                        }
                      ]
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Number of Units Returned',
                      component: 'TextInput',
                      statePath: 'numberOfUnitsReturned',
                      type: 'number',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      validations: {
                        maxLength: {
                          constraint: 254,
                          errorMessage:
                            'The maximum number of characters has been reached'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'available',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'TextArea',
                      label: 'Additional Sample Information',
                      statePath: 'additionalSampleInformation',
                      layout: {
                        width: '50%',
                        display: 'none'
                      },
                      styles: {
                        minHeight: '150px'
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'available',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      label: 'Customer response letter required?',
                      name: 'ComplaintLetter',
                      component: 'Select',
                      statePath: 'letterRequired',
                      layout: {
                        width: '50%',
                        ' > div, > div > div': {
                          margin: 0
                        }
                      },
                      styles: {
                        flexDirection: 'row'
                      },
                      options: [],
                      validations: {
                        required: {
                          constraint: true,
                          errorMessage: ''
                        }
                      },
                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get: 'data.document-data.pq-options.letterRequired'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      component: 'Select',
                      statePath: 'followupRequired',
                      label: 'Follow-up Required?',
                      placeholder: 'Select...',

                      layout: {
                        width: '50%'
                      },

                      referencedProperties: {
                        options: {
                          schemaPath: './',
                          $get:
                            'data.document-data.pq-options.follow-up-required'
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Date Due Diligence 1',
                      component: 'DateInput',
                      statePath: 'dateDueDiligence1',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'followupRequired',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Date Due Diligence 2',
                      component: 'DateInput',
                      statePath: 'dateDueDiligence2',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'followupRequired',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Date Due Diligence 3',
                      component: 'DateInput',
                      statePath: 'dateDueDiligence3',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'followupRequired',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Date Due Diligence 4',
                      component: 'DateInput',
                      statePath: 'dateDueDiligence4',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'followupRequired',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    },
                    {
                      $ref: 'DisplayValueToggle',
                      label: 'Date Due Diligence 5',
                      component: 'DateInput',
                      statePath: 'dateDueDiligence5',
                      layout: {
                        width: '50%',
                        marginRight: '50%'
                      },
                      validations: {
                        date: {
                          constraint: 'full',
                          errorMessage: 'Invalid full date'
                        }
                      },
                      referencedProperties: {
                        'layout.display': {
                          statePath: 'followupRequired',
                          relative: true,
                          $if: [{ $eq: 'yes' }, { $set: 'block' }],
                          $else: { $set: 'none' }
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          $ref: 'PQSaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Contacts',
      component: 'Tab',
      path: 'contacts',
      reconciliationPaths: ['contacts.contact', 'patient.patient'],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'data' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'Contact',
          component: 'Section',
          title: 'Case Contacts',
          statePath: 'contacts',

          elements: [
            {
              $ref: 'LookupContactButton'
            },
            {
              $ref: 'ContactList',
              statePath: 'contact',
              label: 'another contact',
              length: 1
            }
          ]
        },
        {
          id: 'patient',
          component: 'Section',
          title: 'Patient Information',
          statePath: 'patient',

          elements: [
            {
              $ref: 'LookupPatientButton'
            },
            {
              $ref: 'PatientInfo',
              statePath: 'patient'
            }
          ]
        },
        {
          $ref: 'PQSaveSubmitActionSection'
        }
      ]
    },
    {
      title: 'Tracking',
      component: 'Tab',
      path: 'tracking',
      reconciliationPaths: [
        'tracking',
        'reference_numbers',
        'subcases.productQuality.comments'
      ],
      referencedProperties: {
        'layout.visibility': {
          schemaPath: 'data',
          $if: [{ 'location.tab': { $eq: 'tracking' } }, { $set: 'visible' }],
          $else: { $set: 'hidden' }
        }
      },
      sections: [
        {
          id: 'ReferenceNumbers',
          component: 'Section',
          statePath: 'reference_numbers',
          title: 'Reference Numbers',
          elements: [
            { $ref: 'ReferenceNumber' },
            {
              $ref: 'LinkedCases',
              statePath: '/linkedCases'
            },
            {
              $ref: 'ClonedFrom'
            },
            { $ref: 'AERNumbers' }
          ]
        },
        {
          id: 'ReferenceNumbers',
          component: 'Section',
          statePath: '',
          title: 'Submissions',
          elements: [
            {
              id: 'AESubmissionSection',
              component: 'Section',
              elements: [
                {
                  component: 'InfoTable',
                  statePath: '/submissions',
                  sortByKeys: ['value.id', 'value.productIndex'],
                  length: 1,
                  elements: [
                    {
                      component: 'InfoTableRow',
                      elements: [
                        {
                          component: 'InfoTableColumn',
                          title: 'Trilogy SubCase ID',
                          statePath: 'id'
                        },
                        {
                          component: 'InfoTableColumn',
                          title: 'Product',
                          statePath: 'productName'
                        },
                        {
                          component: 'InfoTableColumn',
                          title: 'Submission Date (UTC)',
                          statePath: 'productSubmissionCreatedDate'
                        },
                        {
                          component: 'InfoTableColumn',
                          title: 'PQ Global ID',
                          referencedProperties: {
                            statePath: {
                              relative: true,
                              statePath: 'oneTrackId',
                              $if: [{ $eq: null }, { $set: 'solTRAQsId' }],
                              $else: { $set: 'oneTrackId' }
                            }
                          }
                        },
                        {
                          component: 'InfoTableColumn',
                          title: 'Product ID',
                          statePath: 'productIndex'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          $ref: 'TrackingInformationSection',
          title: 'Master Case Information',
          statePath: 'tracking'
        },
        {
          $ref: 'InternalCommentsSection',
          statePath: 'subcases.productQuality.comments'
        },
        {
          $ref: 'PQSaveSubmitActionSection'
        }
      ]
    },
    { $ref: 'SharedInfo' }
  ]
};
